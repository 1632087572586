:root {
    // max page width
    --size-max: 1200px;
    --size-page: 800px;

    // minimum touch sizing
    --size-touch: 40px;

    // border-radius
    --size-border-radius: .25rem;
    --size-border-radius-full: 100%;
    --size-border-radius-switch: 50px;
    --size-border-radius-meter: 50px;

    // #spacing (padding/margin)
    --size-root-eigth: .125rem;
    --size-root-quarter: .25rem;
    --size-root-half: .5rem;
    --size-root-3quarter: .75rem;
    --size-root-full: 1rem;
    --size-root-2x: 2rem;

    // alpha declarations
    --alpha-full: 1;
    --alpha-3quarter: .75;
    --alpha-half: .5;
    --alpha-quarter: .25;
    --alpha-eighth: .125;
    --alpha-none: 0;

    // z-index
    --depth-negative: -1;
    --depth-header: 3;
    --depth-navToggle:5;
    --depth-nav: 4;
    --depth-lightbox: 6;
    --depth-prelaoder: 6;
    --depth-headings: 2;
    --depth-content: 1;
    
    // icons
    --size-icon-2xlarge: 64px;
    --size-icon-xlarge: 32px;
    --size-icon-large: 24px;
    --size-icon-medium: 16px;
    --size-icon-small: 12px;

    // aniimation
    --duration: 0.5s;
    --duration-quick: 0.25s;
    --duration-slow: 1.5s;

    // typography
    --size-font: 1rem;
    --size-font-small: .75rem;
    --font-awesome: "Font Awesome 6 Free";
    --font-awesome-brands: "Font Awesome 6 Brands";
    --font-family: "Roboto", sans-serif;
    --font-family-heavy: "Roboto Bold", sans-serif;
    --font-family-heading: "Roboto", sans-serif;
    --font-weight: 400;
    --font-weight-light: 300;
    --font-weight-heavy: 700;
    --line-height: 1;

    // scrollbars
    --size-width-track: var(--size-root-quarter);
    --size-width-scrollbar: var(--size-root-quarter);

    // design elements
    --backdrop-filter: blur(5px);
    --border-radius: 0.5rem;
    --border-radius-full: 100%;
    --slope: rotate(2deg);

    // tags
    --size-hr: 2px;

    // forms
    --font-size-button: var(--size-font);
    --font-family-button: var(--font-family);
    --font-weight-button: var(--font-weight);

    --duration-button: 0.25s;
    --size-button-border: 1px;
    --border-radius-button: var(--size-border-radius);

    --size-button-border-h: 1px;
    --border-radius-button-h: var(--size-border-radius);

    --font-family-input: var(--font-family);
    --font-weight-input: var(--font-weight-light);
    --border-radius-input: var(--size-border-radius);

    --font-family-range: var(--font-family);
    --font-weight-range: var(--font-weight-light);
    --border-radius-range: var(--size-border-radius);

    --font-family-select: var(--font-family);
    --font-weight-select: var(--font-weight-light);
    --border-radius-select: var(--size-border-radius);

    --border-radius-checkbox: var(--size-border-radius);
    --font-family-checkbox: var(--font-family);
    --font-weight-checkbox: var(--font-weight-light);

    // accessability
    --focus-border-hidden: 2px dashed transparent;
    --focus-border-active: 2px dashed;
}


:root {
    // custom content
    --size-brand: 60px;
    --size-header: 80px;
    --size-nav-inline-height: 60px;
    --size-footer: 80px;
    --size-nav-width: 300px;
    
    --size-hero-font: 2rem;
    --size-hero-font-small: 80%;

    --size-hero-page-font: 1.5rem;
    
    --size-media: 300px;
    --size-content: 600px;
    --size-content-small: 600px;
    --size-detail: 900px;
    
    --size-galery-media-width: 80vw;
    --size-galery-media-height: 80vh;
    
    // audio player
    --size-player-height-max: 75vh;
    --size-player-height-small: auto;
    --size-player-width-max: 500px;
    --size-player-width: 500px;
    --size-player-width-small: 100vw;
    --size-player-artwork: 300px;
    --size-player-artwork-small: 200px;
}
