.projects {
    @include containerLayout(
        grid,
        $rows: var(--grid-default-rows),
        $columns: var(--grid-default-columns),
        $gap: var(--grid-default-gap),
        $padding: 0,
        $width: 100%,
        $margin: 0
    );

    &__header {
        grid-row: var(--page-row-header);
        grid-column: var(--grid-width-full);
    }

    .search {
        grid-row: var(--page-row-search);
        grid-column: var(--grid-columns-search);

        @include breakpoint(small) {
            grid-column: var(--grid-columns-search);
        }
    }

    &--grid {
        // grid-row: var(--page-row-projects);
        grid-column: var(--grid-width-content);

        @include breakpoint(small) {
            grid-column: var(--grid-width-content-small);
        }
    }
}

.projects {
    &__controls {
        --column-list: 2/3;
        --column-back: 10/11;
        --column-next: 11/12;

        z-index: 2;
        grid-row: var(--page-row-controls);
        grid-column: var(--grid-width-full);
        position: sticky;
        bottom: 0; // forces sticky
        @include containerLayout(
            grid,
            $rows: auto,
            $columns: var(--grid-default-columns),
            $gap: var(--grid-default-gap),
            $padding: var(--size-root-full) 0,
            $width: initial,
            $margin: 0
        );
        background: var(--color-body);
        backdrop-filter: var(--backdrop-filter);
        border-top: 2px solid var(--color-body-secondary);
        transition: all var(--duration);

        .btn__back {
            grid-column: var(--column-list);
        }

        .btn__previous {
            grid-column: var(--column-back);
        }

        .btn__next {
            grid-column: var(--column-next);
        }

        .btn__back,
        .btn__next,
        .btn__previous {
            border: var(--focus-border-hidden);

            &:focus-visible {
                color: var(--color-link-h);
                border-radius: var(--border-radius);
                border: var(--focus-border-active);
            }

            &:hover,
            &:focus:not(:focus-visible) {
                color: var(--color-link-h);
            }
        }
    }

    &--grid {
        @include containerLayout(
            grid,
            $rows: auto,
            $columns: repeat(3, minmax(auto, 1fr)),
            $gap: var(--size-root-full),
            $padding: 0,
            $width: initial,
            $margin: 0
        );
        align-content: flex-start;
        animation: fadeUp var(--duration);

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint(small) {
            grid-template: auto / repeat(1, 1fr);
        }
    }
}

.web,
.design,
.video {
    &__thumb {
        aspect-ratio: 16/9;
        position: relative;
        width: 100%;
        background-color: var(--color-body-secondary);
        background: url(#{$pathToBrand}/icon-brand.png) center no-repeat;
        border: 4px solid transparent;
        border-radius: var(--border-radius);
        overflow: hidden;
        cursor: pointer;
        animation: fadeIn 1s;

        img {
            width: 100%;
            height: auto;
            margin: 0 auto;
            transition: all var(--duration-quick);
        }

        figcaption {
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: var(--color-link);
            padding: var(--size-root-half);
            background-color: var(--color-gallery-overlay);
            backdrop-filter: var(--backdrop-filter);
            white-space: pre-line;
            transition: all var(--duration-quick);

            @include breakpoint(small) {
                font-size: x-small;
            }
        }

        &:hover,
        &:focus {
            // z-index: 1;
            // transform: scale(1.25);
            img {
                filter: grayscale(1);
            }

            figcaption {
                opacity: 1;
            }
        }

        // default hover management
        &:hover,
        &:focus {
            border: 4px solid var(--color-link-h);
            box-shadow: var(--box-shadow);
            transition: all var(--duration-quick);

            figcaption {
                color: var(--color-link-h);
            }
        }
    }
}

.screens {
    @include containerLayout(
        grid,
        $rows: auto,
        $columns: repeat(3, 1fr),
        $gap: var(--size-root-full),
        $padding: var(--size-root-full),
        $width: initial,
        $margin: 0 auto
    );
    align-items: flex-start;
    justify-items: center;
    background: var(--color-body-secondary);
    border-radius: var(--border-radius);

    @include breakpoint(small) {
        grid-template: auto / repeat(2, 1fr);
    }

    img {
        aspect-ratio: 16/9;
        width: 100%;
        object-fit: cover;
        object-position: top;
        border: 4px solid transparent;
        border-radius: var(--border-radius);
        overflow: hidden;
        cursor: pointer;

        &:hover,
        &:focus {
            border: 4px solid var(--color-link-h);
            box-shadow: var(--box-shadow);
            transition: all var(--duration-quick);
        }
    }

    picture {
        aspect-ratio: 16/9;
        width: auto;
        display: grid;
        align-content: center;
        background-color: var(--color-body-secondary);
        border-radius: var(--border-radius);
        overflow: hidden;
        cursor: pointer;

        &:hover,
        &:focus {
            box-shadow: var(--box-shadow);
            transition: all var(--duration-quick);
        }

        img {
            width: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}