:root {
    --color-slider-background: transparent;
    // --color-slider-overlay: rgba(0, 0, 0, 0.6);
    --depth-slider-overlay: 1;

    // slide
    --depth-slider-details: 2;
    --color-slide-background: trandparent;
    --size-slide-width: 100vw;
    --size-slide-height: calc(100vh - var(--size-header));

    // nav vars
    --slide-count: 0;
    --size-slide-nav-width: 50vw;
    --size-slide-nav-width-small: 90vw;
    // --color-slide-link: rgba(50, 50, 50, 0.5);
    // --color-slide-active: rgba(255, 255, 255, 0.5);
    // --color-slide-focus: rgba(255, 255, 255, 0.5);
    --color-slide-nav-background: transparent;

    //scroll vars
    --size-slider-scroll-width: 0;
    --size-slider-scroll-height: 0;
    --color-slider-track: transparent;
    --color-slider-thumb: transparent;
}

.slider__nav {
    z-index: 2;
    position: absolute;
    display: grid;
    grid-template: 1fr / repeat(var(--slide-count), 1fr);
    gap: var(--size-root-full);
    width: var(--size-slide-nav-width);
    left: calc((var(--size-slide-width) - var(--size-slide-nav-width)) / 2);
    bottom: var(--size-root-full);
    justify-content: space-between;
    background: var(--color-slide-nav-background);

    @include breakpoint(small) {
        width: var(--size-slide-nav-width-small);
        left: calc((var(--size-slide-width) - var(--size-slide-nav-width-small)) / 2);
    }

    & > a {
        position: relative;
        display: inline-flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        height: calc(var(--size-touch) / 3);
        text-decoration: none;
        font-size: 0;
        background: var(--color-slide-link);
        border-radius: var(--border-radius);

        @include breakpoint(small) {
            height: calc(var(--size-touch) / 2);
        }
    }

    & > a:active,
    & > a:hover {
        background: var(--color-slide-active);
    }

    & > a:focus {
        background: var(--color-slide-focus);
    }
}

.slider {
    position: relative;
    width: var(--size-slide-width);
    max-width: var(--size-slide-width);
    height: var(--size-slide-height);
    max-height: var(--size-slide-height);
    text-align: center;
    overflow: hidden;
    background: var(--color-slider-background);
    animation: fadeIn var(--duration);

    &__overlay {
        content: "";
        z-index: var(--depth-slider-overlay);
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--color-slider-overlay);
        pointer-events: none;
    }

    /* Don't need button navigation */
    // @supports (scroll-snap-type) {
        & > a {
            display: none;
        }
    // }
}

.slider__slides {
    display: flex;
    height: var(--size-slide-height);
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    // &::-webkit-scrollbar {
    //     width: var(--size-slider-scroll-width);
    //     height: var(--size-slider-scroll-height);
    // }

    // &::-webkit-scrollbar-thumb {
    //     background: var(--color-slider-thumb);
    // }

    // &::-webkit-scrollbar-track {
    //     background: var(--color-slider-track);
    // }
}

.slide {
    scroll-snap-align: start;
    position: relative;
    display: flex;
    flex: 1 0 var(--size-slide-width);
    align-items: center;
    width: var(--size-slide-width);
    height: var(--size-slide-height);
    background: var(--color-slide-background);
    overflow: hidden;

    &__details {
        z-index: var(--depth-slider-details);
    }

    &__media {
        display: contents;
    }

    //slide transitions
    & > &__media {
        transition: all 1s ease-in-out;
    }
    &:target > &__media {
    }

    //from
    & > &__details {
        // opacity: 0;
        transform-origin: center center;
        // transform: translateX(5vw);
        transition: all 1s ease-in-out;
    }

    // to
    &:target > &__details {
    }
}
