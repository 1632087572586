.mode__dark {
    .header--solid {
        box-shadow: none;
    }
}

.header {
    z-index: var(--depth-header);
    position: sticky;
    top: 0;
    @include containerLayout(
        grid,
        $rows: var(--grid-default-rows),
        $columns: var(--grid-default-columns),
        $gap: var(--grid-default-gap),
        $padding: 0,
        $width: 100%,
        $margin: 0
    );
    align-items: center;
    justify-items: center;
    width: 100%;
    height: var(--size-header);
    pointer-events: none;
    // transition: all var(--duration-quick);
    // overflow-x: clip; // safari: is ignored, hides scroll display when nav is engaged

    &__bar{
        z-index: var(--depth-content);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: var(--size-header);
        
        &--on {
            // background-color: var(--color-header);
            backdrop-filter: var(--backdrop-filter);
            animation: fadeDown var(--duration-quick);
        }
        
        &--off {
            animation: fadeOut var(--duration-quick);
        }
    }
}
