.mode__dark {
    // mood initial
    --mood-current: rgba(180, 180, 180, 1);
    
    // mood values
    --mood-aggressive: rgb(190, 10, 55);
    --mood-chill: rgb(10, 148, 190);
    --mood-ambient: rgb(139, 10, 190);
    --mood-thematic: rgb(25, 190, 10);
    --mood-folk: rgb(187, 190, 10);

    // icons
    --color-icon: rgba(255, 0, 110, 1);
    --color-icon-h: rgba(255, 0, 110, .7);

    // design elements
    --color-border-all: rgba(180, 180, 180, 1);
    --color-border-details: rgba(80, 80, 80, 1);
    --box-shadow: 0px 0.5rem 1rem -0.125rem rgba(80, 80, 80, 0.5);
    --box-shadow-shallow: 0px 1px 4px 0 rgba(80, 80, 80, 0.5);
    --box-shadow-inset: inset 0px 0px 2px 4px rgba(80, 80, 80, 0.5);
    --text-shadow: 1px 2px 0.25rem rgba(0, 0, 0, 0.75);
    --filter-drop-shadow: drop-shadow(0px 0px 4px rgba(80, 80, 80, 0.5));

    // tags
    --color-hr: var(--color-content);

    --color-blockquote-icon: rgba(140, 140, 140, 1);
    --color-blockquote-fg: var(--color-content);
    --color-blockquote-bg: rgba(200, 200, 200, 1);
    --color-blockquote-cite: rgba(255, 255, 255, 1);

    --color-table-head-fg: rgba(255, 255, 255, 1);
    --color-table-bg: rgba(200, 200, 200, 1);
    --color-table-head: rgba(140, 140, 140, 1);
    --color-table-fg: var(--color-content);
    --color-table-row-alt: rgba(180, 180, 180, 1);
    --color-table-foot-fg: rgba(255, 255, 255, 1);
    --color-table-foot: rgba(140, 140, 140, 1);

    // forms
    --color-button-fg: rgba(255, 255, 255, 1);
    --color-button-bg: rgba(255, 0, 110, 1);
    --color-button-border: rgb(255, 0, 110, .7);

    --color-button-fg-h: rgba(255, 255, 255, 1);
    --color-button-bg-h: rgba(255, 0, 110, 1);
    --color-button-border-h: rgba(255, 0, 110, 1);

    --color-label: rgba(255, 0, 110, 1);
    --color-placeholder: rgba(255, 0, 110, .7);

    --color-input-fg: var(--color-content);
    --color-input-bg: rgba(60, 60, 60, 1);
    --color-input-border: var(--color-border-all);

    --color-range-thumb: rgba(255, 0, 110, 1);
    --color-range-value: rgba(255, 0, 110, .7);
    --color-range-bg: rgba(60, 60, 60, 1);
    --color-range-border: var(--color-border-all);

    --color-select-fg: var(--color-content);
    --color-select-bg: rgba(60, 60, 60, 1);
    --color-select-border: var(--color-border-all);

    --color-checkbox-check: rgba(255, 0, 110, 1);
    --color-checkbox-fg: var(--color-content);
    --color-checkbox-bg: transparent;
    --color-checkbox-border: var(--color-border-all);
}