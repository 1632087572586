.contact {
    @include containerLayout(
        grid,
        $rows: var(--grid-default-rows),
        $columns: var(--grid-default-columns),
        $gap: var(--grid-default-gap),
        $padding: 0,
        $width: 100%,
        $margin: var(--page-project-detail-offest) 0 0 0
    );
    align-content: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;

    & .response {
        grid-column: 2/12;
        text-align: center;
    }

    form {
        grid-column: var(--form-container);
        border-radius: var(--border-radius);
        animation: fadeUp var(--duration);
        @include containerLayout(
            grid,
            $rows: auto,
            $columns: 1fr,
            $gap: var(--size-root-full),
            $padding: var(--size-root-full),
            $width: 100%,
            $margin: 0
        );

        @include breakpoint(small) {
            grid-column: var(--grid-width-content-small);
        }

        & > header {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            height: auto;
            background: none;

            p {
                flex: 1 0 100%;
                text-align: center;
            }
        }

        .element {
            @include containerLayout(
                grid,
                $rows: auto,
                $columns: 1fr,
                $gap: var(--size-root-half),
                $padding: initial,
                $width: initial,
                $margin: 0
            );
        }

        button {
            margin: 0 0 0 auto;
        }

        label {
            color: var(--color-label);
        }

        input[type="email"],
        input[type="password"],
        input[type="tel"],
        input[type="text"],
        textarea {
            color: var(--color-input-fg);
            font-family: var(--font-family-input);
            font-weight: var(--font-weight-input);
            background-color: var(--color-input-bg);
            padding: var(--size-root-full);
            border-radius: var(--border-radius);
            border: 1px solid var(--color-input-border);

            &:focus::placeholder {
                color: var(--color-placeholder);
                transition: var(--duration);
            }
        }

        textarea {
            width: 100%;
            resize: none;
        }
    }
}
