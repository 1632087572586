.markdown {
    @include containerLayout(
        grid,
        $rows: var(--grid-default-rows),
        $columns: var(--grid-default-columns),
        $gap: var(--grid-default-gap),
        $padding: 0,
        $width: 100%,
        $margin: 0
    );
    align-content: flex-start;

    &__header {
        grid-row: var(--page-row-header);
        grid-column: var(--grid-width-full);
    }

    &__content {
        grid-row: var(--page-row-search);
        grid-column: var(--grid-width-content);
        gap: var(--grid-default-gap);

        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: flex-start;
        align-content: flex-start;

        @include breakpoint(small) {
            grid-column: var(--grid-width-content-small);
        }

        h1,
        h2,
        h3,
        h4,
        p {
            grid-column: var(--grid-width-content);
            width: 100%;
            white-space: pre-line;
        }

        hr {
            @extend .divider;

            @include breakpoint(small) {
                grid-column: span 2;
            }
        }
    }

    .gallery {
        
        &--grid {
            @include containerLayout(
                grid,
                $rows: auto,
                $columns: repeat(3, 1fr),
                $gap: var(--size-root-full),
                $padding: var(--size-root-full),
                $width: initial,
                $margin: 0 auto
            );
            align-items: flex-start;
            justify-items: center;
            background: var(--color-body-secondary);
            border-radius: var(--border-radius);

            @include breakpoint(small) {
                grid-template: auto / repeat(2, 1fr);
            }
        }
    }

    &__media {
        --match-grid-column: ((100vw / 12) - (1rem / 11));
        width: calc(var(--match-grid-column) * 3);
        z-index: var(--depth-content);
        border-radius: calc(var(--border-radius) * 1.5);
        border: 4px solid transparent;
        overflow: hidden;
        cursor: pointer;

        @include breakpoint(small) {
            --match-grid-column: 100%;
            width: var(--match-grid-column);
        }

        &:hover,
        &:focus {
            border-color: var(--color-link-h);
            box-shadow: var(--box-shadow);
            transition: all var(--duration-quick);
        }

        figcaption {
            position: relative;
            display: block;
            color: var(--color-content);
            padding: var(--size-root-half);
            font-size: small;
            line-height: calc(var(--line-height) * 1.25);
            pointer-events: none; // allow click through
        }

        & > img {
            aspect-ratio: 16/9;
            width: 100%;
            object-fit: cover;
            border-radius: var(--border-radius);
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            overflow: hidden;
            pointer-events: none; // allow click through
        }
    }
}
