@keyframes fadeIn {
    from {
    	opacity: 0;
    }
    to   {
    	opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to   {
        opacity: 0;
    }
}

@keyframes fadeUp {
    from {
    	opacity: 0;
    	transform: translate3d(0, 2vh, 0);
    }
    to   {
    	opacity: 1;
    	transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeDown {
    from {
        opacity: 0;
        transform: translate3d(0, -2vh, 0);
    }
    to   {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeLeft {
    from {
        opacity: 0;
        transform: translate3d(-10vw, 0, 0);
    }
    to   {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeRight {
    from {
        opacity: 0;
        transform: translate3d(10vw, 0, 0);
    }
    to   {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to   {
        transform: rotate(180deg);
    }
}

@keyframes move {
    0% {
       background-position: 0 0;
    }
    100% {
       background-position: 50px 50px;
    }
}

@keyframes bounce {
    0% {
       transform: translate3d(0, 0, 0);
    }
    50% {
       transform: translate3d(0, 1vh, 0);
    }
    100% {
       transform: translate3d(0, 0, 0);
    }
}

@keyframes colorChange {
    from {
        filter: hue-rotate(0deg);
    }
    to {
        filter: hue-rotate(360deg);
    }
}

@keyframes loading {
    0% {
       transform: translate3d(0, 0, 0);
    }
    50% {
       transform: translate3d(0, 1vh, 0);
    }
    100% {
       transform: translate3d(0, 0, 0);
    }
}

@keyframes headerBackground {
    0%{background-position:0% 15%}
    50%{background-position:0% 0%}
    100%{background-position:0% 15%}
}