.projects__detail {
    grid-row: var(--page-row-projects);
    grid-column: var(--grid-width-full);
    animation: fadeUp var(--duration) linear;

    @include containerLayout(
        grid,
        $rows: var(--grid-default-rows),
        $columns: var(--grid-default-columns),
        $gap: var(--grid-default-gap),
        $padding: 0,
        $width: 100%,
        $margin: var(--page-project-detail-offest) 0 0 0
    );

    & > *:not(.title--modified) {
        grid-column: var(--grid-width-content);
    }

    p:not(:first-of-type) {
        font-size: smaller;
    }

    p:first-of-type {
        padding-bottom: var(--size-root-half);
        white-space: pre-line;
    }

    p:last-of-type {
        padding-bottom: var(--size-root-half);
    }

    & .btn__link {
        justify-self: flex-start;
        padding: 0 var(--size-root-half);
    }

    &--wrapper {
        height: calc(100vh - (var(--size-header) * 2));
        margin: 0 0 var(--size-root-2x) 0;
        border-radius: var(--border-radius);
        overflow: hidden;

        @include breakpoint(small) {
            height: auto;
        }
    }

    &--media {
        aspect-ratio: 3/2;
        display: block;
        min-width: 100%;
        background-color: var(--color-body-secondary);
        background: url(#{$pathToBrand}/icon-brand.png) center no-repeat;
        border-radius: var(--border-radius);
        overflow: hidden;

        img {
            width: 100%;
            object-fit: cover;
            object-position: center top;
        }
    }

    &--video {
        display: flex;
        min-width: var(--size-media);
        width: 100%;
        margin: 0 0 var(--size-root-full) 0;
        border-radius: var(--size-root-half);
        background-color: var(--color-body-secondary);
        background: url(#{$pathToBrand}/icon-brand.png) center no-repeat;
        @include aspectRatioBox(3, 2);
        animation: fadeIn 1s;
        cursor: pointer;

        @include breakpoint(small) {
            grid-row: 1 / span 1;
        }

        img {
            position: absolute;
            width: 100%;
            height: auto;
            top: 0;
            margin: 0 auto 0 auto;
        }

        video {
            object-fit: contain;
        }
    }
}

// animation
@media (prefers-reduced-motion: no-preference) {
    .projects__detail--wrapper {
        animation-name: fadeOut;
        animation-timing-function: linear;
        animation-direction: both;
        animation-range: 70% exit;
        animation-timeline: view();
    }
}