.search {
    grid-column: var(--grid-columns-search);
    z-index: var(--depth-headings);
    position: sticky;
    top: var(--size-root-full);
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    backdrop-filter: var(--backdrop-filter);

    & > label {
        display: none;
    }

    input[type="search"] {
        appearance: none;
        width: 100%;
        font-size: var(--size-font);
        font-family: var(--font-family-input);
        font-weight: var(--font-weight-input);
        padding: var(--size-root-full);
        color: var(--color-input-fg);
        background-color: var(--color-input-bg);
        border: var(--focus-border-hidden);
        border-radius: var(--border-radius);
        transition: all var(--duration-quick);

        &::placeholder {
            color: var(--color-placeholder);
        }

        
        &:focus,
        &:focus-visible {
            color: var(--color-focus);
            outline: 2px solid var(--color-focus);

            & + i {
                opacity: 0;
            }
        }
    }

    i {
        opacity: 1;
        position: absolute;
        align-self: center;
        right: var(--size-root-half);
        width: var(--size-icon-large);
        height: var(--size-icon-large);
        pointer-events: none;
        transition: var(--duration-quick);

        &:before {
            content: "\f002"; //"\f0b0";
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--size-icon-large);
            height: var(--size-icon-large);
            color: var(--color-link);
            font-family: "Font Awesome 5 Free";
            font-size: var(--size-root-full);
        }
    }
}