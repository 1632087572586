.card {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: var(--size-root-half);
    align-content: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    border: 1px solid var(--color-card-border);
    border-radius: var(--border-radius);
    background-color: var(--color-body-secondary);

    & :not(picture, source, img) {
        padding: 0 var(--size-root-full);
    }

    .badge {
        position: absolute;
        top: -1px;
        right: -1px;
        padding: var(--size-root-half);
        border: 1px solid var(--color-card-border);
        border-radius: 0 var(--border-radius) 0 var(--border-radius);
        background-color: var(--color-body-secondary);
        color: var(--color-content-highlight);
        box-shadow: var(--box-shadow-shallow);
        text-transform: capitalize;
    }

    .date {
        font-size: smaller;
    }

    & > .title {
        font-size: 1.5rem;
    }

    & > p {
        width: 100%;
    }

    & > picture {
        display: block;

        img {
            aspect-ratio: 3/2;
            object-fit: cover;
            object-position: top center;
            width: 100%;
            overflow: hidden;
            border-radius: var(--border-radius) var(--border-radius) 0 0;
        }
    }

    & > button {
        align-self: flex-start;
        margin-top: auto;
        font-size: initial;
        border: var(--focus-border-hidden);
        border-radius: var(--border-radius);

        &:focus-visible {
            color: var(--color-link-h);
            border: var(--focus-border-active);
        }
    }
}