.nav {
    z-index: var(--depth-nav);
    opacity: 0;
    height: 100vh;
    min-height: -webkit-fill-available; // mobile viewport bug fix
    line-height: calc(var(--line-height) * 2);
    background: var(--color-nav);
    backdrop-filter: var(--backdrop-filter);
    overscroll-behavior: contain;
    overflow-y: auto;
    scroll-behavior: smooth;
    pointer-events: none; // ensure's correct nav is clickable when active
    transition: all var(--duration-quick) ease-in;

    @include breakpoint(small) {
        width: 100vw;
    }

    &__link {
        &--active,
        &:hover,
        &:focus:not(:focus-visible) {
            color: var(--color-link-h);
        }

        &:focus-visible {
            color: var(--color-link-h);
            border-radius: var(--border-radius);
            border: var(--focus-border-active);
        }

        i {
            font-family: var(--font-awesome);
            font-weight: 400;
            transition: transform var(--duration);

            &:before {
                width: var(--size-icon-medium);
                height: var(--size-icon-medium);
                font-size: var(--size-icon-medium);
            }
        }

        &--home i:before {
            content: "\f015";
        }

        &--project i:before {
            content: "\f02d";
        }

        &--about i:before {
            content: "\f2bb";
        }

        &--contact i:before {
            content: "\f0e0";
        }

        &--uiux i:before {
            content: "\f07b";
        }

        &--in i:before {
            content: "\f2f6";
        }

        &--out i:before {
            content: "\f2f5";
        }

        &--light i {
            transform: rotate(90deg);
        }

        &--dark i {
            transform: rotate(270deg);
        }

        &--light i:before,
        &--dark i:before {
            content: "\f042";
        }
    }

    &__menu {
        --menu-spacing: 10vh;
        --translate-nav: translateX(var(--size-nav-width));

        opacity: 0;
        position: fixed;
        display: grid;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        width: var(--size-nav-width);
        transform: var(--translate-nav);
        visibility: hidden; //remove from tabIndex
        transition: all var(--duration);

        @include breakpoint(small) {
            --size-nav-width: 100vw;
            padding-top: var(--menu-spacing);
            padding-bottom: var(--menu-spacing);
        }

        &--container {
            display: grid;
            align-content: center;
            justify-items: center;
            margin-top: var(--size-header);
            margin-bottom: var(--size-root-2x); // adds distance between nav & social

            & li a,
            & > li button {
                display: flex;
                align-content: center;
                justify-content: flex-start;
                gap: var(--size-root-full);
                padding: var(--size-root-half);
                width: 100%;
                border: var(--focus-border-hidden);

                &.btn--active,
                &:hover,
                &:focus:not(:focus-visible) {
                    color: var(--color-link-h);
                }

                &:focus-visible {
                    color: var(--color-link-h);
                    border-radius: var(--border-radius);
                    border: var(--focus-border-active);
                }
            }
        }

        &--visible {
            opacity: 1;
            transform: translateX(0);
            visibility: visible; //put into tabIndex
            pointer-events: auto; // initiate pointer events when visible
        }
    }

    ul > li {
        display: flex;
        width: 100%;
    }

    .mode {
        color: var(--color-link);
        font-size: var(--size-font);
        line-height: var(--size-line-height);
        background-color: transparent;
        cursor: pointer;
    }

    .copyright {
        display: block;
        align-self: center;
        justify-self: center;
        margin-bottom: var(--size-header);
        font-size: var(--size-font-small);
        color: var(--color-content);
    }
}

.nav__home {
    grid-column: var(--grid-columns-home);
    justify-self: center;
    z-index: var(--depth-navToggle);
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: var(--size-icon-2xlarge);
    height: var(--size-icon-2xlarge);
    pointer-events: all;
    cursor: pointer;

    &:hover,
    &:focus {
        opacity: var(--alpha-3quarter);
    }

    &--svg {
        width: var(--size-brand);
        fill: var(--color-link);
        transform: scale(0.75);
    }
}

.nav__toggle {
    background-color: grey;
    grid-column: var(--grid-columns-toggle);
    z-index: var(--depth-navToggle);
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size-icon-2xlarge);
    height: var(--size-icon-2xlarge);
    right: var(--size-root-full);
    margin: 0;
    padding: 0;
    color: var(--color-link);
    font-size: var(--size-font-small);
    border: var(--focus-border-hidden);
    background: transparent;
    pointer-events: all;
    cursor: pointer;

    &:focus-visible {
        color: var(--color-link-h);
        border-radius: var(--border-radius);
        border: var(--focus-border-active);
    }

    &:hover,
    &:focus:not(:focus-visible) {
        color: var(--color-link-h);
    }

    & i {
        top: calc((var(--size-icon-large) / 2) - (var(--size-root-full) / 2));
        right: calc((var(--size-icon-large) / 2) - (var(--size-root-full) / 2));
        font-family: var(--font-awesome);
        font-size: var(--size-icon-large);

        &:before {
            content: "\f0c9";
            font-size: var(--size-icon-large);
        }
    }
}
