:root {
    // global layout
    --page-row-header: 1;
    --grid-columns-toggle: 12;
    
    //view 
    --grid-columns-home: 1;
    
    --page-row-projects: 3;
    --page-row-search: 2;
    --grid-columns-search: 3/11;
    
    --page-row-detail: 3;
    --page-row-controls: 4;
    
    --column-media: 2/5;
    --column-content: 5/12;
    
    --form-container: 4/10;
    

    //offset
    --page-offest: calc(var(--size-header) * -1);
    --page-project-detail-offest: calc(var(--size-header) - var(--size-root-2x));
}