:root {
    /* base grid */
    --grid-default-gap: 1rem;
    --grid-default-gap-mobile: 2rem;
    --grid-default-rows: auto;
    --grid-default-columns: repeat(12, 1fr);
    
    --grid-width-full: 1/-1;
    --grid-width-content: 2/12;
    --grid-width-content-medium: 2/12;
    --grid-width-content-small: 2/12;
    
    /*hoc elements */
    --grid-nav: 1;
    --grid-content: 2;
}


@font-face {
    font-family: Roboto;
    src: url(#{$pathToFonts}Roboto/Roboto-Regular.ttf);
    font-display: swap;
}

body {
    // forces ios to use all the viewable area
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // lock body when nav is open
    &.locked {
        overflow: hidden;
    }
}

main {
    background: var(--color-body);
    min-height: 100vh;
    overflow-x: clip;
}

.content {
    min-height: calc(100vh - var(--size-header));
    margin: var(--page-offest) auto 0 auto;

    .reveal-animation {
        animation: fadeIn var(--duration);

        & .divider {
            animation: fadeIn var(--duration-slow) forwards;
        }
    }
}

header {
    @include containerLayout(
        grid,
        $rows: var(--grid-default-rows),
        $columns: var(--grid-default-columns),
        $gap: var(--grid-default-gap),
        $padding: 10vw,
        $width: 100%,
        $margin: 0
    );
    position: relative;
    height: 100vh;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;

    @include breakpoint(small) {
        padding: var(--size-root-full);
    }

    .heading {
        grid-column: var(--grid-width-content);
        z-index: var(--depth-content);
        
        &:focus-visible {
            color: var(--color-body);
            text-shadow: 0px 2px 10px var(--color-link);
        }
    }

    .heading,
    .subheading {
        grid-column: var(--grid-width-content);
    }

    .subheading {
        z-index: var(--depth-content);
        grid-column: var(--grid-width-content);
        color: var(--color-heading-sub);
        font-size: calc(var(--size-font) * 1.25);
    }

    .overview {
        z-index: var(--depth-content);
        grid-column: var(--grid-width-content);
        // font-size: var(--size-hero-page-font);
        animation: fadeUp var(--duration);

        @include breakpoint(small) {
            font-size: initial;
        }
    }

    .divider {
        z-index: var(--depth-content);
        grid-column: var(--grid-width-content);
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-hero-page-background);
        background-size: 600% 600%;
        animation: headerBackground 10s ease-in-out infinite;
        overflow: hidden;
    }
    
    .svg__brand {
        position: absolute;
        width: 100vw;
        height: -webkit-fill-available;
        top: 0;
        left: 0;
        opacity: 0.1;
        transform: translate3d(51vw, 0vh, 0px) scale(1.5);
        animation: brandPulse var(--duration-slow) alternate-reverse infinite; // safari bug, won't display animation of filter
        filter: drop-shadow(0px 1px .75rem var(--color-link));
        
        @include breakpoint(small) {
            transform: translate3d(40vw, 0vh, 0px) scale(3);
        }

        path {
            fill: var(--color-body);
        }
    }


    // &:not(svg) {
    //     // move content on top of the svg
    //     z-index: 1;
    // }
}

.divider {
    width: 10vw;
    margin: var(--size-root-full) 0;
    border: none;
    border-bottom: 2px solid var(--color-hero-page-divider);
    animation: fadeIn var(--duration-slow);

    @include breakpoint(small) {
        width: 20vw;
    }
}

.spacer {
    margin: 0 0 var(--size-root-2x) 0;
}

.title {
    
    &--modified {
        --grid-columns-search: 2/11;
        
        grid-column: var(--grid-columns-search);
        z-index: var(--depth-header);
        position: sticky;
        top: 22px;
        display: inline-block;
        justify-content: start;
        padding: 0 0 0 var(--size-root-2x);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: calc(var(--size-font) * 1.5);
        animation: fadeDown var(--duration);

        @include breakpoint(small) {
            top: 34px;
        }
    }
}

footer {
    @include containerLayout(
        grid,
        $rows: 1fr,
        $columns: repeat(2, 1fr),
        $gap: var(--layout-content-gap),
        $padding: var(--size-root-full),
        $width: var(--size-max),
        $margin: initial
    );

    min-height: var(--size-footer);
    align-self: stretch;
    color: var(--color-footer-fg);
    background: var(--color-footer);

    @include breakpoint(small) {
        grid-template: 1fr / 1fr;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .card,
    .screens > img,
    .markdown__media {
        animation-name: fadeIn;
        animation-timing-function: linear;
        animation-direction: both;
        animation-range: 0 25%;
        animation-timeline: view();
    }
}