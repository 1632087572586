.social {
    @include containerLayout(
        grid,
        $rows: 1fr, 
        $columns: repeat(4, var(--size-icon-xlarge)),
        $gap: var(--size-root-full), 
        $padding: var(--size-root-full),
        $width: initial,
        $margin: initial
    );

    justify-content: center;
    align-content: center;
    border-top: 2px solid var(--color-hero-page-divider);

    & li > a {
        display: inline-flex;
        padding: var(--size-root-half);
        font-size: 0;

        border: var(--focus-border-hidden);

        &:focus-visible {
            color: var(--color-link-h);
            border-radius: var(--border-radius);
            border: var(--focus-border-active);
        }

        &:hover,
        &:focus:not(:focus-visible) {
            color: var(--color-link-h);
        }

        i { 
            display: contents;
            font-family: var(--font-awesome-brands);
            font-weight: 400;
            
            &:before {
                width: var(--size-icon-large);
                height: var(--size-icon-large);
                font-size: var(--size-icon-large);
                line-height: var(--size-icon-large);
            }
        }
    }

    .linkedin i:before {
        content: "\f08c";
    }

    .twitter i:before {
        content: "\e61b";
    }

    .soundcloud i:before {
        content: "\f1be";
    }

    .youtube i:before {
        content: "\f167";
    }
}
