.featured {
    @include containerLayout(
        grid,
        $rows: var(--grid-default-rows),
        $columns: var(--grid-default-columns),
        $gap: var(--grid-default-gap),
        $padding: 0,
        $width: 100%,
        $margin: 0
    );
    min-height: 75vh;
    align-content: center;
    
    &__header {
        padding: var(--size-root-full) 0;
    }

    &__header,
    &--grid {
        grid-column: var(--grid-width-content);
        
        @include breakpoint(medium) {
            grid-column: var(--grid-width-content-medium);
        }

        @include breakpoint(small) {
            grid-column: var(--grid-width-content-small);
        }
    }
}

.featured {

    &--grid {
        @include containerLayout(
            grid,
            $rows: auto,
            $columns: repeat(3, minmax(auto, 1fr)),
            $gap: var(--size-root-full),
            $padding: 0,
            $width: initial,
            $margin: 0
        );
        align-content: center;
        animation: fadeUp var(--duration);

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint(small) {
            grid-template: auto / repeat(1, 1fr);
        }

        & > :last-child:not(i) {
            grid-column: 1/-1;
            font-size: initial;

            a:focus-visible {
                padding: var(--size-root-half);
            }
        }
    }
}
