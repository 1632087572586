.btn {

    &__basic {
        @include button($display: flex, $grow:0, $shrink:1, $flex: auto);
        @include buttonStyle();
        width: fit-content;
        justify-self: center;
    }
    
    &__text {
        @include button($display: inline-flex, $grow:0, $flex: auto, $padding: var(--size-root-quarter) var(--size-root-half));
        @include buttonStyle(text);
        border: var(--focus-border-active);
    }

    &__previous {
        @include button($display: inline-flex, $grow:0, $flex: auto);
        @include buttonStyle(text);
        font-weight: var(--font-weight-button);

        @include breakpoint(small) {
            font-size: 0;
        }

        i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--size-icon-large);
            height: var(--size-icon-large);
            min-height: var(--size-touch);
            font-family: var(--font-awesome);
            font-size: var(--size-root-full);
        
            &:before {
                content: "\f053";
            }
        }
    }

    &__next {
        @include button($display: inline-flex, $grow:0, $flex: auto);
        @include buttonStyle(text);
        font-weight: var(--font-weight-button);

        @include breakpoint(small) {
            font-size: 0;
        }
        
        i {
            order: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--size-icon-large);
            height: var(--size-icon-large);
            min-height: var(--size-touch);
            font-family: var(--font-awesome);
            font-size: var(--size-root-full);
        
            &:before {
                content: "\f054";
            }
        }
    }

    &__back {
        @include button($display: inline-flex, $grow:0, $flex: auto);
        @include buttonStyle(text);
        font-weight: var(--font-weight-button);

        @include breakpoint(small) {
            font-size: 0;
        }

        i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--size-icon-large);
            height: var(--size-icon-large);
            min-height: var(--size-touch);
            font-family: var(--font-awesome);
            font-size: var(--size-root-full);
        
            &:before {
                content: "\f060";
            }
        }
    }

    &__link {
        @include buttonStyle(text);
        @include button($display: flex, $grow:0, $flex: auto, $padding: 0);
        font-weight: var(--font-weight-button);

        i {
            order: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--size-root-full);
            height: var(--size-touch);
            margin: 0 var(--size-root-half);
            font-family: var(--font-awesome);
            font-size: var(--size-root-full);
        
            &:before {
                content: "\f35d";
            }
        }
    }

    &__ghost {
        @include buttonStyle(ghost);
        @include button($display: flex, $grow:0, $flex: auto, $padding: .5rem);
        font-weight: var(--font-weight-button);

        i {
            order: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--size-root-full);
            height: var(--size-touch);
            margin: 0 var(--size-root-half);
            font-family: var(--font-awesome);
            font-size: var(--size-root-full);
        
            &:before {
                content: "\f054";
                color: inherit;
            }
        }
    }

    &--active,
    &--disabled {
        // opacity: .5;
        cursor: default;
    }
}