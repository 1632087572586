.about {
    @include containerLayout(
        grid,
        $rows: var(--grid-default-rows),
        $columns: var(--grid-default-columns),
        $gap: var(--grid-default-gap),
        $padding: 0,
        $width: 100%,
        $margin: 0
    );
    align-content: flex-start;

    &__header {
        grid-row: var(--page-row-header);
        grid-column: var(--grid-width-full);
    }

    // content elements
    &__content {
        @include containerLayout(
            grid,
            $rows: var(--grid-default-rows),
            $columns: var(--grid-default-columns),
            $gap: var(--grid-default-gap),
            $padding: 0,
            $width: 100%,
            $margin: 0
        );

        grid-row: var(--page-row-detail);
        grid-column: var(--grid-width-full);
        animation: fadeUp var(--duration);

        @include breakpoint(small) {
            gap: var(--grid-default-gap-mobile);
        }

        &--media {
            grid-column: var(--column-media);
            align-self: flex-start;
            min-width: var(--size-media);
            border-radius: var(--border-radius-full);
            box-shadow: 0px 10px 20px 5px var(--color-link);
            overflow: hidden;

            @include breakpoint(small) {
                grid-column: var(--grid-width-content-small);
            }
        }

        &--content {
            grid-column: var(--column-content);

            @include breakpoint(small) {
                grid-column: var(--grid-width-content-small);
            }
        }

        h2,
        h3 {
            &:focus-visible {
                color: var(--color-body);
                text-shadow: 0px 2px 10px var(--color-link);
            }
        }

        h2,
        h3,
        h5,
        p,
        details {
            white-space: break-spaces;
        }

        .img {
            position: sticky;
            justify-self: center;
            height: var(--size-media);
            top: calc(var(--size-header) + #{var(--size-root-full)});
            background: url(#{$pathToImages}/profile.png) center no-repeat;
            background-size: contain;
            background-color: var(--color-background-profile);

            @include breakpoint(small) {
                position: initial;
            }
        }
    }
}
