.loading {
    // transition: all 3s;
    // &--start {
    //     display: block;
    //     width: 100vw;
    //     height: 100vh;
    //     background-color: blue;
    //     animation: fadeOut 3s;
    // }

    @keyframes loading {
        from {
            width: 0;
        }
        to {
            width: 100vw;
        }
    }

    &--start {
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        border-bottom: 2px solid var(--color-hero-page-divider);
        animation: loading var(--duration-quick);
    }
}
