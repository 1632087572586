.home {
    margin: var(--page-offest) auto 0 auto;

    background: var(--color-hero-page-background);
    background-size: 600% 600%;
    animation: headerBackground 10s ease-in-out infinite;

    & .hero {
        display: none;
    }
}


.slider {
    --color-slide-link: var(--color-link);
    --color-slide-active: var(--color-link-h);
    --color-slide-focus: var(--color-link-h);
    --size-slide-height: 95vh;
    z-index: 0;
    
    .slide {
        transition: all;
        height: 100vh;
        background-color: transparent;

        &__details {
            opacity: 1;
            grid-gap: 2vw;
            justify-content: flex-start;
            justify-items: flex-start;
            margin: 0 10vw;
            color: var(--color-hero-content);

            display: grid;

            @include breakpoint(small) {
                grid-gap: 5vw;
                justify-content: center;
                justify-items: center;
                margin: 0 auto;
            }

            & .small {
                font-size: 2.5vw;
                text-shadow: 0px 1px 2px var(--color-body);

                @include breakpoint(small) {
                    font-size: 5vw;
                }
            }

            & .large {
                font-size: 5vw;
                text-shadow: 0px 1px 2px var(--color-body);

                @include breakpoint(small) {
                    font-size: 8vw;
                }
            }
        }

        .image__media {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--color-hero-page-background);
            background-size: 600% 600%;
            // animation: headerBackground 10s ease-in-out infinite;
            overflow: hidden;
        }
        
        .svg__brand {
            position: absolute;
            width: 100vw;
            height: -webkit-fill-available;
            top: 0;
            left: 0;
            opacity: 0.1;
            transform: translate3d(51vw, 0vh, 0px) scale(1.5);
            animation: brandPulse var(--duration-slow) alternate-reverse infinite; // safari bug, won't display animation of filter
            filter: drop-shadow(0px 1px .75rem var(--color-link));
            
            @include breakpoint(small) {
                transform: translate3d(40vw, 0vh, 0px) scale(3);
            }
    
            path {
                fill: var(--color-body);
            }
        }

        // animated elements
        &.selected {
            animation: fadeIn var(--duration-slow);

            & > div {
                .slide--message {
                    opacity: 1;
                    animation: fadeUp var(--duration-slow);
                }

                .image {
                    opacity: 1;
                    animation: fadeIn var(--duration-slow);
                }
            }
        }

        &.previous div {
            .slide--message {
                animation: fadeOut var(--duration-quick);
            }

            .image {
                animation: fadeOut var(--duration-quick);
            }
        }
    }
}
